/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* Map */

.hideLeafletLayer {
  display: none;
}

/* PhotoCarousel */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Lightbox */

.yarl__thumbnails_thumbnail_active {
  border-color: #2778FA !important;
}

/* login/password reset/activate/request access */

.authFormsContainer {
  height: 100vh;
  background: linear-gradient(to bottom, #f2fcfe, #1c92d2);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authFormsContainer .formField {
  display: flex;
  line-height: 40px;
  vertical-align: middle;
}

.authFormsContainer .formFieldLabel {
  flex: 1;
}

.authFormsContainer .formFieldInput {
  flex: 2;
}

.authFormsContainer .formFieldError {
  padding: 0;
  margin: 0 !important;
  margin-top: -5px !important;
  margin-bottom: 40px !important;
}

.authFormsContainer .formFieldError span {
  color: red;
  float: right;
}


/* TablePaginationControl */

.drop-up .Select-menu-outer {
  top: auto;
  bottom: calc(100% - 1px);
  border-radius: 4px 4px 0 0;
}

.drop-up.is-open .Select-control {
  border-radius: 0 0 5px 5px;
}

.sectionTest {
  :global .Select-control {
    height: 34px;
  }
  :global .Select-placeholder {
    line-height: 24px;
  }
  :global .Select-value {
    line-height: 30px !important;
  }

  :global .Select-value-label {
    line-height: 30px;
  }

  :global .Select-input {
    height: 30px;
  }
}

.adjust-spacing-inside-page-size-select input:not([type]) {
  padding: 0.678571em 1em;
}

/* PopoutSlider */

.def_slider_SlideWrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 665;
}

.def_slider_SlideWrapper--close {
  animation: def_slider_undim 0.4s ease-out forwards;
}

.def_slider_SlideWrapper--open {
  animation: def_slider_dim 0.3s ease-out forwards;
}

.def_slider_SlideModal {
  transition: width 0.3s ease-out;
}

.h-textRight {
  text-align: right;
}

.h-overflowHidden {
  overflow: hidden !important;
}

.h-overflowAuto {
  overflow: auto !important;
  padding: 20px;
}

.h-displayFlex {
  display: flex !important;
}

.h-flexCol {
  flex-direction: column !important;
}

.h-flexSpaceBetween {
  justify-content: space-between !important;
}

.def_slider_SlideModal {
  position: fixed;
  /*right: -50%;*/
  top: 0;
  bottom: 0;
  width: 35%;
  min-width: 320px;
  background-color: #efefef;
  z-index: 666;
  border-left: #aaa solid 1px;
}

.def_slider_SlideModal--right {
  right: -50%;
}

.def_slider_SlideModal--left {
  left: -50%;
}

.def_slider_SlideModal--open.def_slider_SlideModal--right {
  animation: def_slider_slideIn--right 0.3s ease-out forwards;
}

.def_slider_SlideModal--open.def_slider_SlideModal--left {
  animation: def_slider_slideIn--left 0.3s ease-out forwards;
}

@media (max-width: 767px) {
  .def_slider_SlideModal--open {
    width: 100%;
  }
}

.def_slider_SlideModal--close.def_slider_SlideModal--right {
  animation: def_slider_slideOut--right 0.3s ease-out forwards;
}

.def_slider_SlideModal--close.def_slider_SlideModal--left {
  animation: def_slider_slideOut--left 0.3s ease-out forwards;
}

.def_slider_SlideModal__header {
  display: flex;
  align-items: center;
  background-color: #b3b3b3;
  overflow: hidden;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 667;
}

.def_slider_SlideModal__footer {
  box-shadow: 0 -3px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  float: right;
}

.def_slider_SlideModal__title {
  padding-left: 15px;
  color: #fff;
  font-weight: bolder;
  font-size: 1.5rem;
}

@keyframes def_slider_slideIn--right {
  0% {
    right: calc(-100%);
  }
  100% {
    right: 0;
  }
}

@keyframes def_slider_slideOut--right {
  0% {
    right: 0;
  }
  100% {
    right: calc(-100%);
  }
}

@keyframes def_slider_slideIn--left {
  0% {
    left: calc(-100%);
  }
  100% {
    left: 0;
  }
}

@keyframes def_slider_slideOut--left {
  0% {
    left: 0;
  }
  100% {
    left: calc(-100%);
  }
}

/* PageWrapper */

#adminSideMenuPanelCollapsed {
  width: 52px !important;
}

#adminContentPanelWide {
  width: calc(100% - 52px) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container,
.react-datepicker__input-container > input {
  width: 100%;
}

.ReactTable {
  border-radius: 8px;
  text-align: center;
}

/* AdminPages */

.Select-input input {
  background: rgba(0, 0, 0, 0) !important ;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  padding-left: 0 !important;
}

.adminFormFieldMobile {
  line-height: 38px;
}

.adminFormFieldNormal {
  display: flex;
  line-height: 38px;
}

.adminFormFieldNormal > label {
  flex: 1;
  margin: 0px !important;
}

.adminFormFieldNormal > div {
  flex: 2;
}

.adminFormFieldError {
  padding: 0;
  margin: 0 !important;
  margin-left: -5px !important;
  padding-bottom: 40px !important;
}

.adminFormFieldError > span {
  color: red;
  float: right;
}

.rdw-link-modal-input,
.rdw-embedded-modal-link-input,
.rdw-embedded-modal-size-input {
  margin: unset !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px !important;
  width: 90% !important;
}

.rdw-embedded-modal-size-input {
  margin: unset !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px !important;
  width: 70% !important;
}

/* increase z-index to make option list over toggle button */
.Select .Select-menu-outer {
  z-index: 3;
}
